<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">
              {{ $t('globalTrans.other') }} {{ $t('elearning_tpm.training_schedule') }}
              <slot v-if="$route.query.id">
                {{ $t('elearning_config.update') }}
              </slot>
              <slot v-else>
                {{ $t('globalTrans.entry') }}
              </slot>
            </h4>
          </template>
          <template v-slot:headerAction>
            <router-link to="other-training-schedule" :class="'btn btn-success text-light'">{{ $t('globalTrans.other') }} {{ $t('elearning_tpm.training_schedule') }} {{ $t('globalTrans.list') }}</router-link>
          </template>
          <template v-slot:body>
            <b-row>
              <b-overlay :show="load">
                <b-col lg="12" sm="12">
                  <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                    <b-form id="form" @submit.prevent="handleSubmit(addAll)" @reset.prevent="reset" >
                      <!-- <b-overlay :show="load"> -->
                        <b-row>
                          <b-col lg="6" sm="6">
                            <ValidationProvider name="Fiscal Year"  vid="fiscal_year_id" rules="required|min_value:1">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="fiscal_year_id"
                                  slot-scope="{ valid, errors }"
                              >
                                <template v-slot:label>
                                  {{$t('elearning_config.fiscal_year')}} <span class="text-danger">*</span>
                                </template>
                                <b-form-select
                                    plain
                                    v-model="formData.fiscal_year_id"
                                    :options="fiscalYearList"
                                    id="fiscal_year_id"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                >
                                  <template v-slot:first>
                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                  </template>
                                </b-form-select>
                                <div class="invalid-feedback">
                                  {{ errors[0] }}
                                </div>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                        <b-col lg="6" sm="6" v-if="$store.state.Auth.activeRoleId === 1 || $store.state.Auth.authUser.org_id === 1">
                          <ValidationProvider name="Organization"  vid="org_id" rules="required|min_value:1">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="org_id"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('elearning_config.organization')}} <span class="text-danger">*</span>
                              </template>
                              <b-form-select
                                  plain
                                  v-model="formData.org_id"
                                  :options="orgList"
                                  id="org_id"
                                  :state="errors[0] ? false : (valid ? true : null)"
                              >
                                <template v-slot:first>
                                  <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                              </b-form-select>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="6">
                          <ValidationProvider name="Office Type" vid="office_type_id" rules="required|min_value:1">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="office_type_id"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('elearning_config.office_type')}} <span class="text-danger">*</span>
                              </template>
                              <b-form-select
                                  plain
                                  v-model="formData.office_type_id"
                                  :options="officeTypeList"
                                  id="office_type_id"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  :disabled="$store.state.Auth.activeRoleId === 1 ? false : true"
                              >
                                <template v-slot:first>
                                  <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                              </b-form-select>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="6">
                          <ValidationProvider name="Office" vid="office_id" rules="required|min_value:1">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="office_id"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('globalTrans.office')}} <span class="text-danger">*</span>
                              </template>
                              <b-form-select
                                  plain
                                  v-model="formData.office_id"
                                  :options="officeList"
                                  id="office_id"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  :disabled="$store.state.Auth.activeRoleId === 1 ? false : true"
                              >
                                <template v-slot:first>
                                  <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                              </b-form-select>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                          <b-col lg="6" sm="6">
                            <ValidationProvider name="Training Type"  vid="training_type_id" rules="required|min_value:1">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="training_type_id"
                                  slot-scope="{ valid, errors }"
                              >
                                <template v-slot:label>
                                  {{$t('elearning_config.training_type')}} <span class="text-danger">*</span>
                                </template>
                                <b-form-select
                                  plain
                                  v-model="formData.training_type_id"
                                  :options="trainingTypeList"
                                  id="training_type_id"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  >
                                  <template v-slot:first>
                                      <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                  </template>
                                  </b-form-select>
                                  <div class="invalid-feedback">
                                  {{ errors[0] }}
                                </div>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                          <b-col lg="6" sm="6">
                            <ValidationProvider name="Training Category" vid="training_category_id" rules="required|min_value:1">
                              <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="training_category_id"
                                slot-scope="{ valid, errors }"
                              >
                                <template v-slot:label>
                                  {{$t('elearning_config.training_category')}} <span class="text-danger">*</span>
                                </template>
                                <b-form-select
                                  plain
                                  v-model="formData.training_category_id"
                                  :options="trainingCategoryList"
                                  id="training_category_id"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  >
                                  <template v-slot:first>
                                      <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                  </template>
                                  </b-form-select>
                                <div class="invalid-feedback">
                                  {{ errors[0] }}
                                </div>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                          <b-col lg="6" sm="6">
                            <ValidationProvider name="Training Title" vid="training_title_id" rules="required|min_value:1">
                              <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="training_title_id"
                                slot-scope="{ valid, errors }"
                              >
                                <template v-slot:label>
                                  {{$t('elearning_config.training_title')}} <span class="text-danger">*</span>
                                </template>
                                <b-form-select
                                  plain
                                  v-model="formData.training_title_id"
                                  :options="trainingTitleList"
                                  id="training_title_id"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  >
                                  <template v-slot:first>
                                      <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                  </template>
                                  </b-form-select>
                                <div class="invalid-feedback">
                                  {{ errors[0] }}
                                </div>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                          <b-col lg="6" sm="6">
                            <ValidationProvider name="Circular Memo No" vid="circular_memo_no" rules="required">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="circular_memo_no"
                                  slot-scope="{ valid, errors }"
                              >
                                <template v-slot:label>
                                  {{$t('elearning_iabm.circular_memo_no')}} <span class="text-danger">*</span>
                                </template>
                                <b-form-select
                                    plain
                                    v-model="formData.circular_memo_no"
                                    :options="circularList"
                                    id="circular_memo_no"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                  >
                                  <template v-slot:first>
                                    <b-form-select-option value=''>{{ circularLoading ? 'Loading....' : $t('globalTrans.select') }}</b-form-select-option>
                                  </template>
                                </b-form-select>
                                <div class="invalid-feedback">
                                  {{ errors[0] }}
                                </div>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                          <b-col lg="6" sm="6">
                            <ValidationProvider name="Other Trainee Type" vid="other_trainee_type_id" rules="required">
                              <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="other_trainee_type_id"
                              >
                              <template v-slot:label>
                                {{$t('elearning_config.trainee_type')}} <span class="text-danger">*</span>
                              </template>
                                <b-form-select
                                  plain
                                  v-model="formData.other_trainee_type_id"
                                  :options="traineeTypeList"
                                  id="other_trainee_type_id"
                                  >
                                  <template v-slot:first>
                                      <b-form-select-option :value="0">{{ circularLoading ? 'Loading....' : $t('globalTrans.select') }}</b-form-select-option>
                                  </template>
                                </b-form-select>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                          <b-col lg="6" sm="6">
                            <ValidationProvider name="Training Date" vid="training_date" rules="required">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="training_date"
                                  slot-scope="{ valid, errors }"
                              >
                                <template v-slot:label>
                                  {{ $t('globalTrans.select_date') }} <span class="text-danger">*</span>
                                </template>
                                <b-form-select
                                  plain
                                  v-model="formData.training_date"
                                  :options="selectDate"
                                  id="training_date"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  >
                                  <template v-slot:first>
                                      <b-form-select-option :value=0>{{loading ? 'Loading....' : $t('globalTrans.select') }}</b-form-select-option>
                                  </template>
                                </b-form-select>
                                <div class="invalid-feedback">
                                    {{ errors[0] }}
                                </div>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                          <b-col lg="6" sm="6">
                            <ValidationProvider name="Trainer"  vid="trainer_id" rules="required">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="trainer_id"
                                  slot-scope="{ valid, errors }"
                              >
                                <template v-slot:label>
                                  {{$t('elearning_tpm.trainer')}} <span class="text-danger">*</span>
                                </template>
                                <b-form-select
                                  plain
                                  v-model="formData.trainer_id"
                                  :options="trainerList"
                                  id="trainer_id"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  >
                                  <template v-slot:first>
                                      <b-form-select-option :value=0>{{loading ? 'Loading....' : $t('globalTrans.select') }}</b-form-select-option>
                                  </template>
                                </b-form-select>
                                <div class="invalid-feedback">
                                  {{ errors[0] }}
                                </div>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                          <b-col lg="6" sm="12">
                            <ValidationProvider name="Topic (En)" vid="topic_en" rules="required">
                              <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="topic_en"
                                slot-scope="{ valid, errors }"
                              >
                              <template v-slot:label>
                                {{ $t('elearning_config.topic_en') }} <span class="text-danger">*</span>
                              </template>
                              <b-form-input
                                id="topic_en"
                                v-model="formData.topic_en"
                                :state="errors[0] ? false : (valid ? true : null)"
                              ></b-form-input>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                          <b-col lg="6" sm="12">
                            <ValidationProvider name="Topic (Bn)" vid="topic_bn" rules="required">
                              <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="topic_bn"
                                slot-scope="{ valid, errors }"
                              >
                              <template v-slot:label>
                                  {{ $t('elearning_config.topic_bn') }} <span class="text-danger">*</span>
                              </template>
                              <b-form-input
                                id="topic_bn"
                                v-model="formData.topic_bn"
                                :state="errors[0] ? false : (valid ? true : null)"
                              ></b-form-input>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                          <b-col lg="6" sm="6">
                            <ValidationProvider name="Start Time" vid="start_time" rules="required">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="start_time"
                                  slot-scope="{ valid, errors }"
                              >
                                <template v-slot:label>
                                  {{ $t('globalTrans.start_time') }} <span class="text-danger">*</span>
                                </template>
                                <b-form-input
                                    type="time"
                                    v-model="formData.start_time"
                                    :placeholder="$t('globalTrans.select_date')"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                ></b-form-input>
                                <div class="invalid-feedback">
                                  {{ errors[0] }}
                                </div>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                          <b-col lg="6" sm="6">
                            <ValidationProvider name="End Time" vid="end_time" rules="required">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="end_time"
                                  slot-scope="{ valid, errors }"
                              >
                                <template v-slot:label>
                                  {{ $t('globalTrans.end_time') }} <span class="text-danger">*</span>
                                </template>
                                <b-form-input
                                    type="time"
                                    v-model="formData.end_time"
                                    :placeholder="$t('globalTrans.select_date')"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                ></b-form-input>
                                <div class="invalid-feedback">
                                  {{ errors[0] }}
                                </div>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                          <b-col lg="12" sm="12">
                            <b-button v-if="!scheduleListData" type="submit" variant="primary" class="btn btn-sm btn-primary float-right"><i class="fa fa-plus" aria-hidden="true"></i> {{$t('globalTrans.add_more')}}</b-button>
                          </b-col>
                        </b-row>
                        <!-- <b-row v-if="!scheduleListData">
                          <div class="col-md-12" v-if="trainingCalendar.training_start_date && trainingCalendar.training_end_date">
                            <fieldset class="p-2 w-100">
                              <legend class="px-2 w-50 shadow-sm">{{ $t('elearning_tpm.training_calendar') }}</legend>
                                <table class="table" style="width:100%">
                                  <tr>
                                    <th style="width: 20%">{{$t('elearning_iabm.training_start_date')}}</th>
                                    <th style="width: 5%">:</th>
                                    <th style="width: 25%">
                                      <slot v-if="trainingCalendar.training_start_date">
                                        {{trainingCalendar.training_start_date | dateFormat}}
                                      </slot>
                                    </th>
                                    <th style="width: 25%">{{$t('elearning_iabm.training_end_date')}}</th>
                                    <th style="width: 5%">:</th>
                                    <th style="width: 25%">
                                      <slot v-if="trainingCalendar.training_end_date">
                                        {{trainingCalendar.training_end_date | dateFormat}}
                                      </slot>
                                    </th>
                                    <th></th>
                                  </tr>
                                </table>
                            </fieldset>
                          </div>
                        </b-row> -->
                      <!-- </b-overlay> -->
                    </b-form>
                  </ValidationObserver>
                  <ValidationObserver ref="form1"  v-slot="{ handleSubmit, reset }">
                    <b-form id="form1" @submit.prevent="handleSubmit(createData)" @reset.prevent="reset" >
                      <b-row v-if="!scheduleListData">
                        <div class="col-md-12" id="form">
                          <fieldset class="p-2 w-100">
                            <legend class="px-2 w-50 shadow-sm">{{ $t('globalTrans.other') }} {{$t('elearning_tpm.training_schedule')}} {{$t('globalTrans.list')}}</legend>
                            <table class="table" style="width:100%">
                              <thead class="thead">
                                <tr>
                                  <th style="width:15%">{{$t('globalTrans.date')}}</th>
                                  <th style="width:15%">{{$t('globalTrans.time')}}</th>
                                  <th style="width:20%">{{$t('elearning_config.topic')}}</th>
                                  <th style="width:18%">{{$t('elearning_config.speaker_facilitator')}}</th>
                                  <th style="width:10%">{{$t('globalTrans.action')}}</th>
                                </tr>
                              </thead>
                              <template v-if="scheduleList.length === 0">
                                <tr>
                                  <th colspan="4" class="text-center text-danger">{{$t('globalTrans.noDataFound')}}</th>
                                </tr>
                              </template>
                              <template v-else>
                                <tr v-for="(schedule,index) in scheduleList" :key="index">
                                  <td>{{schedule.training_date | dateFormat}}</td>
                                  <td>{{schedule.start_time | time12FormateTraining}} - {{schedule.end_time | time12FormateTraining}}</td>
                                  <td>
                                    {{ $i18n.locale === 'bn' ? schedule.topic_bn : schedule.topic_en }}
                                  </td>
                                  <td>
                                    {{ $i18n.locale === 'bn' ? schedule.speaker_bn : schedule.speaker }}
                                  </td>
                                  <td>
                                    <b-button type="button" variant="danger" class="btn btn-sm btn-danger float-right" @click="scheduleList.splice(index, 1)"><i class="ri-delete-bin-line m-0"></i></b-button>
                                  </td>
                                </tr>
                              </template>
                            </table>
                          </fieldset>
                        </div>
                      </b-row>
                      <span v-if="scheduleListData && formData.circular_memo_no">
                        <h3 class="text-center text-danger">{{$t('elearning_tpm.circular_schedule_text')}}</h3>
                      </span>
                      <div class="row">
                        <div class="col-sm-3"></div>
                        <div class="col text-right">
                          <b-button type="button" variant="primary" @click="createData" class="mr-2">{{ saveBtnName }}</b-button>
                          <router-link to="training-schedule" class="btn btn-danger">{{ $t('globalTrans.cancel') }}</router-link>
                        </div>
                      </div>
                    </b-form>
                  </ValidationObserver>
                </b-col>
              </b-overlay>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <!-- <pre>{{scheduleList}}</pre> -->
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
import { otherTrainingScheduleStore, trainingScheduleUpdate, otherTrainingScheduleList, circularList, scheduleCircularData } from '../../api/routes'
import flatpickr from 'flatpickr'
import { isNotEmpty } from '@amcharts/amcharts4/.internal/core/utils/Utils'

export default {
  props: ['id'],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  created () {
    if (this.$route.query.circular_memo_no && this.$route.query.office_id) {
      this.getscheduleCircular()
    } else {
        this.formData = Object.assign({}, this.formData, {
        fiscal_year_id: this.$store.state.TrainingElearning.currentFiscalYearId
      })
      if (this.$store.state.Auth.activeRoleId !== 1) {
        this.formData.org_id = this.$store.state.Auth.authUser.org_id
        this.formData.office_type_id = this.$store.state.Auth.authUser.office_type_id
        this.formData.office_id = this.$store.state.Auth.authUser.office_id
        this.officeTypeList = this.getOfficeTypeOfficerList(this.$store.state.Auth.authUser.office_type_id)
      }
    }
  },
  mounted () {
    core.index()
    flatpickr('.fromDate', {})
  },
  data () {
    return {
      loading: false,
      load: false,
      saveBtnName: this.id ? this.$t('elearning_config.update') : this.$t('globalTrans.save'),
      formData: {
        sheduleId: 0,
        circular_memo_no: '',
        batch_no: 0,
        fiscal_year_id: 0,
        org_id: 0,
        office_id: 0,
        office_type_id: 0,
        training_category_id: 0,
        training_type_id: 0,
        other_trainee_type_id: 0,
        training_title_id: 0,
        trainer_id: 0,
        training_date: 0,
        topic_en: '',
        topic_bn: '',
        start_time: '',
        end_time: ''
      },
      trainers: [],
      scheduleRemove: [],
      scheduleListData: false,
      scheduleList: [],
      officeList: [],
      officeTypeList: [],
      trainerLoading: false,
      traineeTypeList: [],
      trainingTypeList: [],
      trainingCategoryList: [],
      trainingElearningServiceBaseUrl: trainingElearningServiceBaseUrl,
      guestTrainerListData: [],
      trainingTitleList: [],
      batchList: [],
      allBatchListData: [],
      listBatch: [],
      circularList: [],
      trainerList: [],
      trainingCalendar: {
        training_start_date: '',
        training_end_date: ''
      },
      courseModuleError: '',
      circularLoading: false,
      moduleLoading: false,
      trainerErrors: [],
      otherTraineeTypeList: [],
      selectDate: []
    }
  },
  computed: {
    orgList: function () {
      return this.$store.state.commonObj.organizationProfileList.filter(item => item.status === 0)
    },
    guestTrainerList: function () {
      const listObject = this.guestTrainerListData
      const tmpList = listObject.map((obj, index) => {
        if (this.$i18n.locale === 'bn') {
          return { value: obj.value, text: obj.text_bn }
        } else {
          return { value: obj.value, text: obj.text_en }
        }
      })
      return tmpList
    },
    trainingScheduleSetup: function () {
      return this.$store.state.TrainingElearning.commonObj.trainingScheduleSetup.filter(item => item.value !== 3)
    },
    fiscalYearList: function () {
      return this.$store.state.commonObj.fiscalYearList.filter(item => item.status === 0)
    },
    sheduleType: function () {
      return this.$store.state.TrainingElearning.commonObj.sheduleType
    },
    courseList: function () {
      return this.$store.state.TrainingElearning.commonObj.courseList
    },
    allBatchList () {
      return [
        { value: 1, text: this.$i18n.locale === 'en' ? '1st' : '১ম', text_en: '1st', text_bn: '১ম' },
        { value: 2, text: this.$i18n.locale === 'en' ? '2nd' : '২য়', text_en: '2nd', text_bn: '২য়' },
        { value: 3, text: this.$i18n.locale === 'en' ? '3rd' : '৩য়', text_en: '3rd', text_bn: '৩য়' },
        { value: 4, text: this.$i18n.locale === 'en' ? '4th' : '৪র্থ', text_en: '4th', text_bn: '৪র্থ' },
        { value: 5, text: this.$i18n.locale === 'en' ? '5th' : '৫ম', text_en: '5th', text_bn: '৫ম' },
        { value: 6, text: this.$i18n.locale === 'en' ? '6th' : '৬ষ্ঠ', text_en: '6th', text_bn: '৬ষ্ঠ' },
        { value: 7, text: this.$i18n.locale === 'en' ? '7th' : '৭ম', text_en: '7th', text_bn: '৭ম' },
        { value: 8, text: this.$i18n.locale === 'en' ? '8th' : '৮ম', text_en: '8th', text_bn: '৮য়' },
        { value: 9, text: this.$i18n.locale === 'en' ? '9th' : '৯ম', text_en: '9th', text_bn: '৯ম' },
        { value: 10, text: this.$i18n.locale === 'en' ? '10th' : '১০ম', text_en: '10th', text_bn: '১০ম' }
      ]
    }
  },
  watch: {
    'formData.org_id': function (newValue, oldValue) {
      this.trainingTypeList = this.getTypeList(newValue)
      if (newValue !== oldValue) {
          if (this.$store.state.Auth.activeRoleId === 1) {
            this.officeTypeList = this.getOfficeTypeList(newValue)
          }
      }
    },
    'formData.training_type_id': function (newValue, oldValue) {
      if (newValue !== oldValue) {
        this.trainingCategoryList = this.getCategoryList(newValue)
      } else {
        this.trainingCategoryList = []
      }
    },
    'formData.training_category_id': function (newValue, oldValue) {
      if (newValue !== oldValue) {
        this.trainingTitleList = this.getTrainingTitleList(newValue)
      } else {
        this.trainingTitleList = []
      }
    },
    'formData.fiscal_year_id': function (newValue, oldValue) {
      if (newValue !== oldValue) {
        this.getCircularList()
        // this.getSheduleList()
      }
    },
    'formData.training_title_id': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.getCircularList()
      }
    },
    'formData.office_type_id': function (newValue) {
      this.officeList = this.getOfficeList(newValue)
    },
    'formData.circular_memo_no': function (newValue, oldValue) {
      if (newValue !== oldValue) {
        const allBatchListData = this.allBatchListData.find(allBatchListData => allBatchListData.circular_memo_no === newValue)
        if (typeof allBatchListData !== 'undefined') {
          this.formData.batch_no = allBatchListData.batch_no
        }
      }
      this.getOtherTraineeType()
    },
    // 'formData.circular_memo_no': function (newVal, oldVal) {
    //   if (newVal !== oldVal) {
    //     this.getOtherTraineeType()
    //   }
    // },
    'formData.other_trainee_type_id': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.getSheduleList()
      }
    }
  },
  methods: {
    async getOtherTraineeType () {
      this.circularLoading = true
      const serchData = {
        org_id: this.formData.org_id,
        fiscal_year_id: this.formData.fiscal_year_id,
        office_id: this.formData.office_id,
        office_type_id: this.formData.office_type_id,
        circular_memo_no: this.formData.circular_memo_no
      }
      const result = await RestApi.getData(trainingElearningServiceBaseUrl, '/iabm/circular-publications/other-trainee-type' + '/' + 'iab_circular_publications', serchData)
      if (!result.success) {
        this.otherTraineeTypeList = []
      } else {
        this.otherTraineeTypeList = result.otherTraineeTypeList
        this.getTraineeType()
      }
      this.circularLoading = false
    },
    getTraineeType () {
      const typeData = this.otherTraineeTypeList.map((obj, index) => {
        if (this.$i18n.locale === 'bn') {
            return { value: obj.id, text: obj.other_trainee_type_bn }
          } else {
            return { value: obj.id, text: obj.other_trainee_type }
          }
        })
        if (typeData) {
          this.traineeTypeList = typeData
        } else {
          this.traineeTypeList = []
        }
    },
    getOfficeTypeList (orgId) {
      const OfficeTypeList = this.$store.state.commonObj.officeTypeList.filter(item => item.status === 0)
      if (orgId) {
        return OfficeTypeList.filter(item => item.org_id === orgId)
      }
      return OfficeTypeList
    },
    getOfficeList (officeTypeId) {
      const officeList = this.$store.state.commonObj.officeList.filter(item => item.status === 0)
      if (officeTypeId) {
        return officeList.filter(item => item.office_type_id === officeTypeId)
      }
      return officeList
    },
    getOfficeTypeOfficerList (officeTypeId) {
      const OfficeTypeList = this.$store.state.commonObj.officeTypeList.filter(item => item.status === 0)
      if (officeTypeId) {
        return OfficeTypeList.filter(item => item.value === officeTypeId)
      }
      return OfficeTypeList
    },
    getTypeList (orgId) {
      if (this.formData.org_id) {
        const type = this.$store.state.TrainingElearning.commonObj.trainingTypeList.filter(item => item.status === 1)
        if (orgId) {
          return type.filter(item => item.org_id === parseInt(orgId))
        }
        return type
      }
    },
    circluarList () {
      const listObject = this.allBatchListData.filter(allBatchListData => parseInt(allBatchListData.training_title_id) === parseInt(this.formData.training_title_id))
      const tmpList = listObject.map((obj, index) => {
        if (this.$i18n.locale === 'bn') {
          return { value: obj.circular_memo_no, text: obj.circular_memo_no, batch_no: obj.batch_no }
        } else {
          return { value: obj.circular_memo_no, text: obj.circular_memo_no, batch_no: obj.batch_no }
        }
      })
      this.circularList = tmpList
    },
    async getscheduleCircular () {
      this.load = true
      const serchData = {
        circular_memo_no: this.$route.query.circular_memo_no,
        batch_no: this.$route.query.batch_no
      }
      const result = await RestApi.getData(trainingElearningServiceBaseUrl, scheduleCircularData, serchData)
      if (result.success) {
        this.formData.fiscal_year_id = result.data.fiscal_year_id
        this.formData.org_id = result.data.org_id
        this.formData.training_type_id = result.data.training_type_id
        this.formData.training_category_id = result.data.training_category_id
        this.formData.training_title_id = result.data.training_title_id
        this.formData.circular_memo_no = result.data.circular_memo_no
        this.formData.batch_no = result.data.batch_no
        this.allBatchListData = result.batchList
        this.getSheduleList()
        this.load = false
      } else {
        this.load = false
      }
    },
    async getCircularList () {
      if (this.formData.fiscal_year_id && this.formData.training_title_id) {
        this.circularLoading = true
        const serchData = {
          fiscal_year_id: this.formData.fiscal_year_id,
          training_type_id: this.formData.training_type_id,
          training_category_id: this.formData.training_category_id,
          office_id: this.formData.office_id,
          training_title_id: this.formData.training_title_id
        }
        const result = await RestApi.getData(trainingElearningServiceBaseUrl, circularList + '/' + 'iab_circular_publications', serchData)
        if (!result.success) {
          this.allBatchListData = []
        } else {
          this.allBatchListData = result.batchList
          this.circluarList()
        }
        this.circularLoading = false
      }
    },
    addAll () {
      let speaker = ''
      let speakerBn = ''
        const trainerObj = this.guestTrainerListData.find(doc => doc.value === parseInt(this.formData.trainer_id))
        if (typeof trainerObj !== 'undefined') {
          speaker = trainerObj.text_en
          speakerBn = trainerObj.text_bn
        }
      const checkData = {
        id: this.formData.sheduleId,
        circular_memo_no: this.formData.circular_memo_no,
        batch_no: this.formData.batch_no,
        fiscal_year_id: this.formData.fiscal_year_id,
        office_type_id: this.formData.office_type_id,
        office_id: this.formData.office_id,
        org_id: this.formData.org_id,
        training_category_id: this.formData.training_category_id,
        training_type_id: this.formData.training_type_id,
        training_title_id: this.formData.training_title_id,
        trainer_id: this.formData.trainer_id,
        other_trainee_type_id: this.formData.other_trainee_type_id,
        training_date: this.formData.training_date,
        start_time: this.formData.start_time,
        end_time: this.formData.end_time,
        speaker: speaker,
        speaker_bn: speakerBn,
        topic_en: this.formData.topic_en,
        topic_bn: this.formData.topic_bn
      }
      if (this.formData.sheduleId) {
        const datas = this.scheduleList.map((item, index) => {
          if (item.id === this.formData.sheduleId) {
            return Object.assign({}, item, checkData)
          } else {
            return Object.assign({}, item)
          }
        })
        this.scheduleList = datas
      } else {
        this.scheduleList.push(checkData)
      }
      this.sheduleReset()
    },
    sheduleReset () {
      this.formData.trainer_id = 0
      // this.formData.training_date = null
      this.formData.start_time = ''
      this.formData.end_time = ''
      this.formData.sheduleId = 0
      this.$refs.form.reset()
    },
    getCategoryList (typeId) {
      if (this.formData.training_type_id) {
       const trainingCategoryList = this.$store.state.TrainingElearning.commonObj.trainingCategoryList.filter(item => item.status === 1)
       if (typeId) {
         return trainingCategoryList.filter(item => item.training_type_id === typeId)
       }
       return trainingCategoryList
      }
    },
    getTrainingTitleList (categoryId) {
      if (this.formData.training_category_id) {
        const trainingTitleList = this.$store.state.TrainingElearning.commonObj.trainingTitleList.filter(item => item.status === 1)
        if (categoryId) {
          return trainingTitleList.filter(item => item.training_category_id === categoryId)
        }
        return trainingTitleList
      }
    },
    async getSheduleList () {
      if (this.formData.fiscal_year_id && this.formData.circular_memo_no) {
        this.loading = true
        this.load = true
        const params = {
          no_pagination: true,
          fiscal_year_id: this.formData.fiscal_year_id,
          circular_memo_no: this.formData.circular_memo_no,
          other_trainee_type_id: this.formData.other_trainee_type_id,
          office_id: this.formData.office_id
        }
        const result = await RestApi.getData(trainingElearningServiceBaseUrl, otherTrainingScheduleList, params)
        if (!result.success) {
          this.scheduleList = []
          this.guestTrainerListData = []
          this.loading = false
          this.load = false
        } else {
          if (isNotEmpty(result.data)) {
              this.scheduleList = result.data
              this.scheduleListData = false
          } else {
            this.scheduleListData = false
          }
          if (result.data.length !== 0) {
            this.formData.training_type_id = result.data[0].training_type_id
            this.formData.training_category_id = result.data[0].training_category_id
            this.formData.training_title_id = result.data[0].training_title_id
            this.formData.circular_memo_no = result.data[0].circular_memo_no
            this.formData.batch_no = result.data[0].batch_no
          }
          this.guestTrainerListData = result.guestTrainerList
          const tmpList = result.dateList.map((obj, index) => {
            return { value: obj, text: obj }
          })
          const trainerList = result.guestTrainerList.map((obj, index) => {
            if (this.$i18n.locale === 'bn') {
              return { value: obj.value, text: obj.text_bn }
            } else {
              return { value: obj.value, text: obj.text_en }
            }
          })
          this.trainerList = trainerList
          this.selectDate = tmpList
          this.loading = false
          this.load = false
        }
      }
    },
    async createData () {
      this.load = true
      let result = null
      const datas = this.scheduleList
      if (this.formData.id) {
        result = await RestApi.postData(trainingElearningServiceBaseUrl, `${trainingScheduleUpdate}/${this.formData.id}`, datas)
      } else {
        result = await RestApi.postData(trainingElearningServiceBaseUrl, otherTrainingScheduleStore, datas)
      }
      this.load = false
      if (result.success) {
        this.$toast.success({
          title: 'Success',
          message: this.id ? this.$t('elearning_config.update_msg') : this.$t('globalTrans.save_msg'),
          color: '#D6E09B'
        })
        this.$router.push('/training-e-learning-service/tpm/other-training-schedule')
      } else {
        this.trainerErrors = result.errors
        this.$refs.form1.setErrors(result.errors)
      }
  }
  }
}
</script>
